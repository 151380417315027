require('./bootstrap');
window.loadImage = require('./vendor/load-image');
window.Cookies = require('js-cookie');

$('.js-select2').select2({
    theme: 'bootstrap4',
});

$(function () {
    window.showNotification = function (type, message) {
        if (type) {
            const classPrefix = type === "error" ? "danger" : type
            iziToast[type]({
                message: message,
                class: 'iziToast-' + classPrefix || '',
                position: "topRight"
            });
        }
    }
})

// summernote
$('.summernote-editor').summernote({
    height: 200,                 // set editor height
    disableDragAndDrop: true,
    minHeight: null,             // set minimum height of editor
    maxHeight: null,             // set maximum height of editor
    focus: false,                  // set focus to editable area after initializing summernote
    toolbar: [
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']]
    ]
});

// sweetToast
document.onreadystatechange = function () {
    if (document.readyState === "complete") {
        if (typeof window.swal !== 'undefined'){
            const cookieString = window.Cookies.get("sweetToast");
            if(typeof cookieString !== 'undefined') {
                try {
                    let toastObj = JSON.parse(cookieString);
                    if (typeof toastObj.message === 'undefined') {
                        return
                    }
                    const span = document.createElement("span");
                    span.innerHTML = toastObj.message;

                    window.swal({
                        content: span,
                        icon: toastObj.level
                    }).then(() => {
                        window.Cookies.remove("sweetToast", { "Path": '/', "Domain": "." + window.location.hostname });
                        window.Cookies.remove("sweetToast", { "Path": '/'});
                        window.Cookies.remove("sweetToast");
                    })
                } catch (e) {
                    console.error(e.message)
                }
            }
        }
    }
}





